import styled, { css } from 'styled-components'
import { OverlayBronzeHover, OverlayHover } from '../../components/PageBuilderSection/styles'
export type TOverlay = 'none' | 'blue' | 'bronze' | 'black'

export const OVERLAY_COLOR: Record<TOverlay, string> = {
  none: 'transparent',
  blue: 'linear-gradient(0deg, rgba(38, 22, 10, 0) 0%, rgb(1, 24, 43) 100%)',
  bronze: 'linear-gradient(0deg, rgba(38, 22, 10, 0) 0%, #231610 100%)',
  black: 'rgba(0, 0, 0, 0.2)',
}
export const CardWrapper = styled.div<{ overlay?: TOverlay }>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  min-height: 360px;
  aspect-ratio: 16/7;

  &.video {
    aspect-ratio: 16/9;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    min-height: 350px;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.sm} {
    min-height: 290px;
  }

  &::before {
    background: ${({ overlay }) => OVERLAY_COLOR[overlay || 'none']};
    width: 100%;
    height: 176px;
    position: absolute;
    left: 0;
    z-index: 3;
    content: '';
    top: 0;
  }
`

export const CardContainer = styled.div<{ overlay?: TOverlay }>`
  width: 100%;
  height: 100%;
  position: absolute;
  ${({ overlay }) => (overlay === 'bronze' ? OverlayBronzeHover : OverlayHover)}
`

export const CardBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const CardContent = styled.div<{ textColor?: string }>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor} !important;
      * {
        color: ${textColor} !important;
      }
    `}
`

export const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  position: absolute;
  left: 0;
  bottom: 35px;

  padding-left: 35px;
  padding-right: 35px;

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    bottom: 20px;

    padding-left: 20px;
    padding-right: 20px;
  }
`
