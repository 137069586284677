/** @jsx jsx **/
// @ts-ignore
import { jsx, Grid, Box, Heading, Text, Container, BaseStyles, Flex } from 'theme-ui'
import Layout from '~/components/layout'
import { Button } from '~/components/button'
import { graphql, useStaticQuery } from 'gatsby'
import { space } from '~/gatsby-plugin-theme-ui'
import MarkdownIt from '~/components/MarkdownIt'
import SanitizeHtml from '~/components/sanitize-html'
import { GatsbyImageType } from '~/@types/models'
import Banner from '~/componentsV2/banner'
import useMediaQuery from '~/hooks/use-media-query'
import { SaleItemImage } from './black-friday'

type DiscountItemType = {
  buttonLabel: string
  buttonLink: string
  title: string
  background: GatsbyImageType
}

type QueryResult = {
  pageData: {
    frontmatter: {
      title: string
      note: string
      discountCodeMessage: {
        message: string
        enabled: boolean
      }
      discounts: DiscountItemType[]
      salesCollections: DiscountItemType[]
      image: GatsbyImageType
      bannerText: string
    }
    html: string
  }
}

const query = graphql`
  query {
    pageData: markdownRemark(frontmatter: { templateKey: { eq: "sale" } }) {
      frontmatter {
        title
        note
        discountCodeMessage {
          message
          enabled
        }
        discounts {
          buttonLabel
          buttonLink
          title
        }
        salesCollections {
          buttonLabel
          buttonLink
          title
          background {
            ...heroImage
          }
        }
        image {
          ...heroImage
        }
        bannerText
      }
      html
    }
  }
`
const SaleItem = ({ item }: { item: DiscountItemType }) => {
  const { title, buttonLabel, buttonLink } = item
  return (
    <Grid
      sx={{
        bg: 'productBackground',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: [180, null, 285],
        p: [space.md, null, space.md2],
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box>
        <Heading
          as="h3"
          variant="title2"
          mb={space.md}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Heading>
        <Button
          as="link"
          label={buttonLabel}
          to={buttonLink}
          sx={{
            backgroundColor: 'transparent',
            borderColor: 'text',
            '& span': {
              color: 'text',
              textDecoration: 'underline',
            },
          }}
        />
      </Box>
    </Grid>
  )
}

const SalePage = () => {
  const {
    pageData: {
      frontmatter: { title, discountCodeMessage, salesCollections, image, bannerText },
      html: body,
    },
  } = useStaticQuery<QueryResult>(query)

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  return (
    <Layout headerVariant="default" title={title}>
      <Container>
        <Banner url={image.childImageSharp.fluid} overlay="bronze">
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 2,
              left: 0,
              top: 0,
            }}
          >
            <Flex
              dangerouslySetInnerHTML={{ __html: bannerText }}
              sx={{
                flexDirection: 'column',
                maxWidth: ['98%', null, null, 1300],
                position: 'relative',
                width: '100%',
                height: '100%',
                margin: '0 auto',
                color: 'bgDarkBlue',
                textAlign: 'center',
                fontFamily: 'heading',
                fontSize: isMobile ? 20 : 45,
                '& div': {
                  width: '100%',
                },
                '& .bold': {
                  fontWeight: 700,
                },
                '& .text-sm': {
                  fontSize: isMobile ? 14 : 28,
                  marginBottom: isMobile ? 4 : 16,
                  fontWeight: 'normal',
                },
                '& .text-md': {
                  fontSize: isMobile ? 16 : 32,
                  marginBottom: isMobile ? 5 : 20,
                  fontWeight: 'normal',
                },
                '& .underline': {
                  marginTop: isMobile ? 5 : 20,
                },
                '& .text-top': {
                  position: 'absolute',
                  top: 20,
                  left: 0,
                },
                '& .text-italic': {
                  fontStyle: 'italic',
                },
                '& .text-center': {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                },
                '& .text-white': {
                  color: 'white',
                },
                '& .mb-lg': {
                  marginBottom: isMobile ? 40 : 80,
                },
                '& .mb-md': {
                  marginBottom: isMobile ? 20 : 40,
                },
                '& .mb-sm': {
                  marginBottom: isMobile ? 10 : 20,
                },
              }}
            />
          </Box>
        </Banner>
        {discountCodeMessage.enabled && (
          <Box
            sx={{
              my: [40, null, 80],
              fontSize: [20, null, 24],
              lineHeight: '38px',
              color: 'muted',
              textAlign: 'center',
              strong: {
                color: 'text',
              },
              px: 24,
            }}
          >
            <BaseStyles>
              <MarkdownIt content={discountCodeMessage.message} />
            </BaseStyles>
          </Box>
        )}
        {/* {discounts && (
          <Grid columns={['1fr', null, 'repeat(3, 1fr)']} gap={[32, null, 24]}>
            {discounts.map(item => (
              <SaleItem item={item} />
            ))}
          </Grid>
        )} */}
        {salesCollections && (
          <Grid columns={['1fr', null, 'repeat(3, 1fr)']} gap={[32, null, 24]}>
            {salesCollections.map(item => (
              <SaleItemImage item={item} />
            ))}
          </Grid>
        )}
        <Container
          variant="containerPrimary"
          sx={{
            my: [40, null, 80],
          }}
        >
          <Text
            variant="micro"
            sx={{
              color: 'muted',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <SanitizeHtml content={body} />
            </Box>
          </Text>
        </Container>
      </Container>
    </Layout>
  )
}

export default SalePage
